:root {
	font-family: Arial, Helvetica, sans-serif;

	--font-0: 8px;
	--font-1: 10px;
	--font-2: 12px;
	--font-3: 14px;
	--font-4: 16px;
	--font-5: 18px;
	--font-6: 20px;
	--font-7: 24px;
	--font-8: 28px;
	--font-9: 32px;
	--font-10: 36px;
}

@media(max-width: 1300px) {
	:root {
		--font-0: 6px;
		--font-1: 8px;
		--font-2: 10px;
		--font-3: 12px;
		--font-4: 14px;
		--font-5: 16px;
		--font-6: 18px;
		--font-7: 20px;
		--font-8: 24px;
		--font-9: 28px;
		--font-10: 32px;
	}
}

@media(max-width: 1100px) {
	:root {
		--font-0: 5px;
		--font-1: 7px;
		--font-2: 9px;
		--font-3: 11px;
		--font-4: 13px;
		--font-5: 15px;
		--font-6: 17px;
		--font-7: 19px;
		--font-8: 21px;
		--font-9: 23px;
		--font-10: 27px;
	}
}

@media(min-width: 1500px) {
	:root {
		
		--font-0: 10px;
		--font-1: 12px;
		--font-2: 14px;
		--font-3: 16px;
		--font-4: 18px;
		--font-5: 20px;
		--font-6: 24px;
		--font-7: 28px;
		--font-8: 32px;
		--font-9: 36px;
		--font-10: 40px;
	}
}

@media(min-width: 2000px) {
	:root {
		
		--font-0: 14px;
		--font-1: 16px;
		--font-2: 18px;
		--font-3: 20px;
		--font-4: 24px;
		--font-5: 28px;
		--font-6: 32px;
		--font-7: 36px;
		--font-8: 40px;
		--font-9: 44px;
		--font-10: 48px;
	}
}