:root {
	/* LAYOUT */
	--color-layout-0: #000000;
	--color-layout-0-modal: #00000099;
	--color-layout-1: #1F1F1F; /* 111111 - table, map, 121212 card bg, etc  */
	--color-layout-1-table-border: #111111;
	--color-layout-1-panel: #121212; 
	--color-layout-2: #2B2B2B;
	--color-layout-3: #333333;
	--color-layout-4: #474747; /* 44444 -toggle button, roa form button, 555 - datepicker */
	--color-layout-5: #555555;
	--color-layout-6: #666666;
	--color-layout-7: #757575; /* 777 - uptime /gray text, 7f7f7f - door health gauge empty */
	--color-layout-8: #888888;
	--color-layout-9: #999999;
	--color-layout-A: #AAAAAA; /* a0a0a0 - gw_disconnected, ababab - alarms,events timeline line, */
	--color-layout-B: #BBBBBB; /* bbb8bb - column filter border */
	--color-layout-C: #CCCCCC;
	--color-layout-D: #DDDDDD;
	--color-layout-E: #EEEEEE;
	--color-layout-F: #FFFFFF; /* f5f5f5 - spinner */

	/* INDICATORS */
	--color-indicator-green: #34CB81;
	--color-indicator-yellow: #FFEE17;
	--color-indicator-red: #EB1616;
	--color-indicator-gray: #757575;

	/* IOT STATES */
	--color-iot-state-unitoffline_flag: #FFFFFF;
	--color-iot-state-breakdown_abnormal_flag: #EB1616;
	--color-iot-state-resting_flag: #FFEE17;
	--color-iot-state-specialop_noopensr_flag: #00C6FF;
	--color-iot-state-healthy_flag: #34CB81 ;

	/* GROUPED ELEVATOR STATES */
	--color-grouped_evevator_state-red: #D0151C;
	--color-grouped_evevator_state-green: #26BE71;
	--color-grouped_evevator_state-yellow: #F5F649;
	--color-grouped_evevator_state-orange: #F06702;

	/* ALARMS EVENTS */
	--color-alarms_events-alarms: #FFEE17;
	--color-alarms_events_events: #FF7602;
	--color-alarms_events_event_buffer_link: #0080FF;
	--color-alarms_events-robustel-flags-cleared: "#FFFFFF";
	--color-alarms_events-robustel-flags-triggered: "#EB1616";

	/* DOOR HEALTH */
	--color-door_health_green: #34CB81;
	--color-door_health_yellow: #FFEE17;
	--color-door_health_green: #EB1616;
	--color-door_health_delay_alarm: #03A1E5;

	/* UPTIME HEARTBEAT */
	--color-uptime_heartbeat-gw_disconnected: #A0A0A0;
	--color-uptime_heartbeat-lift_link_disconnected: #FFFFFF;
	--color-uptime_heartbeat-esl_disconnected: #FFFFFF;
	--color-uptime_heartbeat-running: #34CB81;
	--color-uptime_heartbeat-resting: #FFEE17;
	--color-uptime_heartbeat-special: #00C6FF;
	--color-uptime_heartbeat-breakdown: #EB1616;
	--color-uptime_heartbeat-unknown: #000000;

	/* SERVICE */
	--color-service: #FCC23F;

	/* ROA */
	--color-roa: #00FFFF;

	/* HARD SHUTDOWN */
	--color-hard_shutdown: #FF0000; /* D7425A - notifcation */

	/* Anomaly */
	--color-anomaly: #F18B32; /* D7425A - notifcation */

	/* PERFORMANCE */
	--color-performance: #34CB81;
}